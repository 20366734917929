var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"home",class:{
    'hide-scroll': _vm.isChatPannelOpened || _vm.isParticipantPannelOpened,
    'wrapper-waiting-room': 'waitRoom' === _vm.meetDetails.room,
    'zoomed-view': _vm.fullScreenMode
  },attrs:{"id":"wrapper"}},[('meetRoom' === _vm.meetDetails.room)?_c('header-vue',{attrs:{"toggle-sidebar":_vm.toggleSidebar,"toggle-full-screen":_vm.toggleFullScreen}}):_vm._e(),_c('section',{directives:[{name:"show",rawName:"v-show",value:(
      'meetRoom' === _vm.meetDetails.room || 'waitRoom' === _vm.meetDetails.room
    ),expression:"\n      'meetRoom' === meetDetails.room || 'waitRoom' === meetDetails.room\n    "}],class:{
      'view-collapsed': _vm.isChatPannelOpened || _vm.isParticipantPannelOpened
    },attrs:{"id":"main-wrapper"}},[_c('div',{attrs:{"id":"main-content"}},[_c('section-vue',{attrs:{"toggle-full-screen":_vm.toggleFullScreen,"toggle-sidebar":_vm.toggleSidebar,"is-chat-pannel-opened":_vm.isChatPannelOpened}}),_c('footer-vue',{attrs:{"toggle-sidebar":_vm.toggleSidebar,"is-chat-pannel-opened":_vm.isChatPannelOpened,"is-participant-pannel-opened":_vm.isParticipantPannelOpened}})],1)]),_c('aside',{class:{
      'view-collapsed': _vm.isChatPannelOpened || _vm.isParticipantPannelOpened
    },attrs:{"id":"sidebar"}},[_c('div',{attrs:{"id":"sidebar-content"}},[(_vm.isParticipantPannelOpened)?_c('participant-vue',{attrs:{"toggle-sidebar":_vm.toggleSidebar}}):_vm._e(),(_vm.isChatPannelOpened)?_c('chat-vue',{attrs:{"toggle-sidebar":_vm.toggleSidebar}}):_vm._e()],1)]),_c('transition',{attrs:{"name":"zoom"}},[(
        'meetRoom' === _vm.meetDetails.room &&
          _vm.userId === _vm.hostInfo.id &&
          _vm.waitingRoomUsers.length > 0 &&
          _vm.openParticipantPermissionPopup
      )?_c('participant-permission-vue',{attrs:{"participant":_vm.waitingRoomUsers[0],"hide-participant-permission":_vm.hideParticipantPermission}}):_vm._e()],1),(_vm.modal.display)?_c('modal-vue',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c(_vm.modal.bodyComponentName,{tag:"component"})]},proxy:true}],null,false,4237825704)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }